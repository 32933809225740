export default {
  methods: {
    init_attachments_set() {
      // 초기 첨부파일 정보파 file selector 에 표시 될수 있도록
      // 첨부 파일은
      // var index = 0 ;
      for (var attfile of this.attachments) {
        console.log('attfile => ', attfile);
        if (attfile.type == 'logo' || attfile.type == 'main') {
          this.fileMain = {
            name: attfile.originalname,
            filename: attfile.filename,
            download_url: attfile.download_url,
          };
        } else if (attfile.type == 'applicationform') {
          // this.fileMain = {
          //   name: attfile.originalname,
          //   filename: attfile.filename,
          // };

          if (attfile.order != null && attfile.order != undefined) {
            let order = attfile.order;
            this.applicationformFiles[order] = {
              name: attfile.originalname,
              filename: attfile.filename,
              download_url: attfile.download_url,
            };
            // index++
          }
        } else {
          if (attfile.order != null && attfile.order != undefined) {
            let order = attfile.order;
            this.files[order] = {
              name: attfile.originalname,
              filename: attfile.filename,
              download_url: attfile.download_url,
            };
            // index++
          }
          // else {
          //   this.files[index++] = {
          //       name : attfile.originalname,
          //       filename: attfile.filename
          //   }
          // }
        }
      }
    },
    onSubmitModifyFiles(board_name, next_menu) {
      // 파일 변동이 있는 경우.
      // 첨부파일 변동 사항음 별도로 요청. ( Multipart/form-data는 PATCH와 PUT, DELETE를 지원하지 않는다. )
      // 첨부파일 변동은 Edit 콤포넌트생성시 attachments 에 리스트 저장이 필요함.
      // 삭제 / 추가 로 구성됨.
      // this.files에 File 객체가 생긴것은 추가된것임. (기존에 차지하고 있던것은 삭제가 필요함.)
      // this.files에 File 객체가 아닌 객체가 있는 경우 기존 파일이 유지되는것임.
      // tils.files에 File 객체가 없는경우 있다가 사라진경우(1) 이거나 애초에 없는경우 (2) 인경우임.
      // - 이 경우 (1)인경우 삭제 메시지를 보내야함.
      // update attachment
      let formData = new FormData();
      formData.append('owner_id', this.item.id);
      formData.append('board_name', board_name);

      var addFiles = [];
      var keepObjects = [];
      var deleteObjects = [];

      // 주 파일 수정 여부 판단 board_name 에 따라서 fileMain fieldname 변경 필요
      var mainfieldname = 'fileMain';

      if (board_name == 'recruitment_announce') {
        mainfieldname = 'applicationform';
      } else {
        mainfieldname = 'fileMain';
      }

      if (this.fileMain instanceof File) {
        addFiles.push(this.fileMain);
        formData.append(mainfieldname, this.fileMain);
      } else {
        if (this.fileMain != null && Object.keys(this.fileMain).length !== 0) {
          console.log('this.fileMain is not File. but Object ');
          keepObjects.push(this.fileMain); // 유지 되어야 하는 파일 정보로 기록 ( 삭제 파일을 가리기 위함. )
        } else {
          // this.fileMain is emptyp. default. 원래 파일이 없었음.
          console.log('원래 파일이 없었음.');
        }
      }

      ///
      ///
      /// 첨부파일들 체크
      ///
      ///
      var count = 0;
      for (let fileobj of this.files) {
        if (fileobj instanceof File) {
          // 추가될 파일
          addFiles.push(fileobj);
          let fieldname = 'files[' + count + ']';
          formData.append(fieldname, fileobj);
          console.log('fileobj ', fileobj, ' is  File');
        } else if (typeof fileobj == 'object') {
          if (fileobj != null && Object.keys(fileobj).length !== 0) {
            console.log(
              'fileobj ',
              fileobj,
              ' is not File,  not empty object '
            );
            keepObjects.push(fileobj); // 유지 되어야 하는 파일 정보로 기록 ( 삭제 파일을 가리기 위함. )
          } else {
            console.log('fileobj ', fileobj, ' is not File,  empty object ');
          }
        }
        count++;
      }

      count = 0;
      if(this.applicationformFiles != undefined){
        for (let fileobj of this.applicationformFiles) {
          if (fileobj instanceof File) {
            // 추가될 파일
            addFiles.push(fileobj);
            let fieldname = 'applicationform[' + count + ']';
            formData.append(fieldname, fileobj);
            console.log('fileobj ', fileobj, ' is  File');
          } else if (typeof fileobj == 'object') {
            if (fileobj != null && Object.keys(fileobj).length !== 0) {
              console.log(
                'fileobj ',
                fileobj,
                ' is not File,  not empty object '
              );
              keepObjects.push(fileobj); // 유지 되어야 하는 파일 정보로 기록 ( 삭제 파일을 가리기 위함. )
            } else {
              console.log('fileobj ', fileobj, ' is not File,  empty object ');
            }
          }
          count++;
        }
      }

      // files에 없고 attachments에 있는 파일은 삭제되어야 할 파일임.
      for (let item of keepObjects) {
        console.log('keep attachments ', item);
      }

      for (let ele of addFiles) {
        console.log('files to add => ', ele);
      }

      for (var initItem of this.attachments) {
        if (!keepObjects.find(el => el.filename == initItem.filename)) {
          // 삭제하여야 할 대상임.
          console.log('삭제하여야 할 대상 => ', initItem);
          deleteObjects.push(initItem); // 초기에 첨부파일 정보로 내려준 데이터와 비교하여 남겨진것 이외에 삭제할것들 정보
        }
      }

      for (var delitem of deleteObjects) {
        console.log('files to deleted => ', delitem);
        formData.append('deleteAttachmentIdList[]', delitem.id);
      }

      this.$store
        .dispatch('req_create_attachments', formData)
        .then(res => {
          console.log('attachment ret ', res.data);
          this.onModifyCompleted(next_menu);
        })
        .catch(error => {
          console.log('error => ', error);
        });
    },
  },
};
